//Overall div
.cv_card {
    display: flex;
    flex-direction: column;
    z-index: 1;
    width: 700px;
/*     padding: 5px; */
    position: relative;

    background-color: #CDCAC6;
/*     box-shadow: 3px 3px 0 #474350, 3px 3px #FFFFF3 inset; */

    //Overall div title
    h3 {
        display: flex;
        align-items: center;
        align-self: center;

        height: 40px;
        width: 100%;

        background-color: #30345A;
        color: #FFFFF3;

        padding-left: 10px;
    }

    //Nav settings
    ul {
        display: flex;
        padding: 10px 0 10px 15px;
        align-items: center;
        gap: 7px;
    }

    //Main content - main div
    .cv_card_main {
        display: flex;
        gap: 7px;
        padding: 7px;
        box-shadow: 2px 2px 0 #474350, 2px 2px #FFFFF3 inset;

        //Left side (list of files div)
        .cv_card_files {
            display: flex;
            flex-direction: column;
            width: 30%;
            gap: 30px;

            background-color: #FDF0D8;
            box-shadow: 2px 2px 0 #FFFFF3, 2px 2px #474350 inset;
            overflow-x: scroll;
            padding: 40px 0;
        }

        //Right side (details div)
        .cv_card_details {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            gap: 20px;
            overflow-x: scroll;

            padding: 40px 0;
            width: calc(70% - 5px);

            background: linear-gradient(#FFD0D0, #FDF0D8);
            box-shadow: 2px 2px 0 #FFFFF3, 2px 2px #474350 inset;

            //Shared properties between children divs
            .cv_card_title,
            .cv_card_skills,
            .cv_card_tools,
            .cv_card_hobbies,
            .cv_card_global_skills,
            .cv_card_objectives {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                width: 90%;
                padding: 2px 0px 2px 2px;

                h3 {
                    background-color: #FFADC7;
                    color: #474350;
                }

                img {
                    height: 20px;
                    width: 20px;
                }

                ul {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 20px;
                    padding: 20px;
                    width: 100%;

                    li {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                    }
                }
            }

            //First div (work + edu)
            .cv_card_title {
                background-color: #FFFFF3;
                box-shadow: 2px 2px 0 #474350, 2px 2px #FFFFF3 inset;

                h3 {
                    background-color: #FFADC7;
                    border-bottom: 2px solid #474350;
                    color: #474350;
                }

                ul {

                    li {
                        height: 25px;
                    }
                }

            }

            //Skills div (work + edu)
            .cv_card_skills {
                background-color: #F1EDFF;
                box-shadow: 2px 2px 0 #FFFFF3, 2px 2px #474350 inset;

                ul {

                    li {
                        height: 40px;
                        gap: 20px;

                        progress {
                            border-radius: 0;
                            box-shadow: 2px 2px 0 #FFFFF3, 2px 2px #474350 inset;
                            height: 20px;
                            width: 140px;

                            /* Vendor prefixes for Chrome */
                            &::-webkit-progress-value {
                                background-color: #30345A;
                            }

                            &::-webkit-progress-bar {
                                background-color: #D9D9D9;
                                width: 140px;
                            }
                        }

                        progress::-moz-progress-bar {
                            background-color: #30345A;
                        }
                    }

                }
            }

            //Tools div (work + edu)
            .cv_card_tools {
                background-color: #FFFFF3;
                box-shadow: 2px 2px 0 #FFFFF3, 2px 2px #474350 inset;

                ul {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;

                    .cv_card_tool {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        flex-basis: 40%;
                    }

                }

            }

            //Search div (work + edu)
            .cv_card_search {
                padding: 20px;
                background-color: #D9D9D9;
                box-shadow: 2px 2px 0 #FFFFF3, 2px 2px #474350 inset;

                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 20px;
                width: 90%;
            }

            //Skills div (stats)
            .cv_card_global_skills {
                background-color: #F1EDFF;
                box-shadow: 2px 2px 0 #474350, 2px 2px #FFFFF3 inset;

                li {

                    .check_icon_box {
                        display: flex;
                        gap: 5px;
                    }
                }
            }


            //Shared properties btw hobbies div and objectives div (stats)
            .cv_card_hobbies,
            .cv_card_objectives {
                box-shadow: 2px 2px 0 #474350, 2px 2px #FFFFF3 inset;
            }

            //Hobbies div (stats)
            .cv_card_hobbies {
                background-color: #FFFFF3;
            }

            //Objectives div (stats)
            .cv_card_objectives {
                background-color: #D9D9D9;
            }

        }

    }

    .cv_card_footer {
        height: 40px;
        background-color: #D9D9D9;
        box-shadow: 2px 2px 0 #FFFFF3, 2px 2px #474350 inset;
        margin-top: 5px;
        padding-left: 10px;

        display: flex;
        align-items: center;
    }
}

@media screen and (max-width:767px) {
    .phone_stats_container {
        display: flex;
        width: 90%;
        align-self: center;
        margin-bottom: 70px;
        margin-top: 10px;

        .phone_stats {
            flex-direction: column;
            width: 100%;

            .cv_card_details {
                padding-top: 20px;
                width: 100%;
                max-height: 100%;

                .cv_card_skills {

                    ul {
                        li {
                            height: 50px;

                            progress {
                                width: 100px;


                            &::-webkit-progress-bar {
                                width: 100px;
                            }
                            }
                        }
                    }

                }
            }
        }
    }
}
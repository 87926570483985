//------- RESOLUTION : 1400px and more screens (base)

//Overlay within the modal

.modal_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; //Ensure the overlay appears above other content

    //Modal itself basic settings

    .modal {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #CDCAC6;

        width: 550px;
        height: 500px;

        //Title (blue bar + params)

        .modal_title {
            display: flex;
            flex-direction: column;

            //Blue bar

            .modal_title_up {

                display: flex;
                justify-content: space-between;
                align-items: center;
                color: #FFFFF3;

                height: 40px;
                width: 540px;

                background-color: #30345A;

                margin: 5px;
                padding: 0 10px;

                .close_button {
                    cursor: pointer;
                }

            }

            //Params

            nav {
                height: 30px;
                display: flex;
                align-items: center;
                padding-left: 15px;

                ul {
                    display: flex;
                    gap: 7px;
                }
            }
        }

        //Modal main div

        .modal_main {
            width: 540px;
            height: 370px;

            background: linear-gradient(#FFD0D0, #FDF0D8);
            box-shadow: 2px 2px 0 #FFFFF3, 2px 2px #474350 inset;

            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 30px;
            overflow: scroll;

            padding: 30px;

            .go_to_project {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 15px;
                height: 90px;
                text-decoration: none;
                color: inherit;

                cursor: pointer;

                img {
                    height: 60px;
                }
            }

            .project_details {
                width: 100%;

                display: flex;
                flex-direction: column;
                gap: 20px;
            }
        }

        //Modal footer

        .modal_footer {
            display: flex;
            align-items: center;

            width: 540px;
            height: 40px;
            margin-top: 5px;
            padding-left: 10px;

            box-shadow: 2px 2px 0 #FFFFF3, 2px 2px #474350 inset;

        }
    }
}


//--------- RESOLUTION : Screens between 1000 and 1399px (small laptops) and between 999px and 768px (tablets)

@media screen and (max-width:1399px) {

    //Overlay within the modal

    .modal_overlay {

        .modal {

            .modal_main {

                .go_to_project {

                    img {
                        height: 40px;
                    }
                }
            }

        }
    }
}

//--------- RESOLUTION : 350px to 767px (phones)

@media screen and (max-width:767px) {

    .modal_overlay {

        background-color: rgba(0, 0, 0, 0.6);

        .modal {
            width: 350px;
            height: 500px;

            //Title (blue bar + params)

            .modal_title {
                width: 100%;
                align-items: center;

                //Blue bar

                .modal_title_up {
                    width: 98%;
                }

                //Params

                nav {
                    display: none;
                }

            }

            //Modal main div

            .modal_main {
                width: 98%;
                height: 60vh;
                gap: 30px;
                padding: 30px;

                .go_to_project {

                    img {
                        height: 50px;
                    }
                }
            }

            .cv_card {

                margin-top: 0;
                margin-bottom: 5px;
                padding: 20px 0;

                .cv_card_main {

                    width: 100%;
                    box-shadow: none;

                    .cv_card_details {
                        background: none;
                        box-shadow: none;
                        width: 100%;
                        padding: 0;

                        .cv_card_title,
                        .cv_card_skills,
                        .cv_card_tools,
                        .cv_card_hobbies,
                        .cv_card_global_skills,
                        .cv_card_objectives,
                        .cv_card_search {
                            width: 90%;

                            ul {
                                padding: 20px 15px;
                            }
                        }

                        .cv_card_tools {
                            ul {
                                li {
                                    height: 30px;
                                }
                            }
                        }

                    }

                }

            }

            //Modal footer

            .modal_footer {
                width: 98%;
                margin: 5px;
            }
        }

    }

}
//-------- RESOLUTION : 1400px and more && 1000px to 1400px screens (base + small laptops)

//Base settings for one note

.note {
    height: 200px;
    width: 200px;

    background-color: #FFFFF3;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 5px;

    .note_title {
        height: 30px;
        width: 190px;

        background-color: #CDCAC6;

        display: flex;
        justify-content: flex-end;
        align-items: center;

        padding-right: 10px;

    }

    img {
        height: 90px;
        width: 190px;
        object-fit: cover;
        margin-top: 5px;
    }

    .note_text {
        height: 150px;
        line-height: 20px;
        padding: 10px;
    }
}

//------- RESOLUTION : 999px to 768px (tablets)

@media screen and (max-width:999px) {

    //Base settings for one note

    .note {
        height: 120px;
        width: 120px;
        padding-top: 2px;

        .note_title {
            height: 30px;
            width: 116px;

            padding-right: 10px;

        }

        img {
            height: 40px;
            width: 116px;
            margin-top: 2px;
        }

        .note_text {
            height: 70px;
            line-height: 10px;
            font-size: 0.7em;
        }
    }

}

@media screen and (max-width:767px) {

    .note {
        height: 240px;
        width: 240px;
        padding-top: 2px;

        .note_title {
            height: 30px;
            width: 236px;

            padding-right: 10px;

        }

        img {
            height: 70px;
            width: 236px;
            margin-top: 2px;
        }

        .note_text {
            height: 140px;
            line-height: 10px;
            font-size: 1em;
            line-height: 20px;
            padding-top: 20px;
        }
    }

}
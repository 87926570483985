//Profile basic settings
.cv_profile {
    display: flex;
    flex-direction: column;
    z-index: 1;
    width: 700px;
    padding: 5px;
    margin-top: 40px;

    background-color: #CDCAC6;
    box-shadow: 3px 3px 0 #474350, 3px 3px #FFFFF3 inset;

    //Window title
    h3 {
        display: flex;
        align-items: center;
        align-self: center;

        height: 40px;
        width: 100%;

        background-color: #30345A;
        color: #FFFFF3;

        padding-left: 10px;
        margin-bottom: 5px;
    }

    //Nav general settings
    ul {
        display: flex;
        padding: 10px 0 10px 15px;
        border-bottom: 1px #474350 solid;
        box-shadow: 0px 1px 0 #FFFFF3;
    }

    //Nav 1
    .cv_nav_1 {
        align-items: center;
        gap: 7px;
    }

    //Nav 2
    .cv_nav_2 {
        gap: 30px;
        align-items: center;

        li {
            display: flex;
            align-items: flex-end;
            gap: 5px;

            img {
                height: 20px;
                width: 20px;
            }
        }
    }

    .cv_mail {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 10px 0 10px 15px;

        img {
            height: 20px;
            width: 20px;
        }

        .cv_mail_bar {
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 5px 150px 5px 10px;
            background-color: white;
        }

        .cv_mail_send {
            display: flex;
            align-items: center;
            gap: 10px;
            cursor: pointer;
        }

    }

    //Profile pic
    video {
        height: 280px;
        width: 100%;
        object-fit: cover;
        box-shadow: 3px 3px 0 #FFFFF3, 3px 3px #474350 inset;
    }

    .cv_title {

        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 30px 15px;
        margin-top: 5px;
        background: linear-gradient(#FFD0D0, #FDF0D8);
        box-shadow: 2px 2px 0 #FFFFF3, 2px 2px #474350 inset;

        h1 {
            font-size: 3em;
            text-transform: uppercase;
        }

    }


}

@media screen and (max-width:767px) {

    .cv_profile {
        z-index: 1;
        width: 95%;
        padding: 5px;
        margin-top: 40px;
    
        //Window title
        h3 {   
            height: 6vh;
        }
    
        //Nav general settings
        ul {
            padding: 10px;
        }
    
        //Nav 1
        .cv_nav_1 {
            display: none;
        }
    
        //Nav 2
        .cv_nav_2 {
            gap: 30px;
    
            li {
                display: flex;
                font-size: 0.9em;
    
                img {
                    display: none;
                }
            }
        }
    
        .cv_mail {
            justify-content: space-between;
            gap: 10px;
            padding-left: 10px;
            width: 100%;
    
            img {
                height: 17px;
                width: 17px;
            }
    
            .cv_mail_bar {
                padding: 5px;
                width: 100%;
            }
    
            .cv_mail_send {
                display: none;
            }
    
        }
    
        //Profile pic
        video {
            height: 150px;
        }
    
        .cv_title {   
            gap: 10px;
            padding: 20px 10px;

    
            h1 {
                font-size: 2em;
                text-transform: uppercase;
            }

            p {
                font-size: 0.9em;
            }
    
        }
    
    
    }


}
//------- RESOLUTION : 1400px and more (base)
//Settings for one project

.project {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;

    cursor: pointer;

    img {
        width: 50px;
    }

    p {
        padding: 3px 5px;
        text-align: center;
    }
}

.main_page {
    flex-basis: 25%;
}

.selected {
    p {
        background-color: #30345A;
        color: #FFFFF3;
    }
}

//------- RESOLUTION : 1000px to 1399px (small laptops)

@media screen and (max-width:1399px) {

    //Settings for one project

    .project {
        gap: 5px;

        img {
            width: 40px;
        }

        p {
            padding: 3px 5px;
        }
    }

}

//----- RESOLUTION : 900px to 999px (tablets)

@media screen and (max-width:999px) {

    //Settings for one project

    .project {
        gap: 5px;

        img {
            width: 40px;
        }

        p {
            padding: 3px 5px;
        }
    }

    .main_page {
        flex-basis: 33%;
    }

}


//----- RESOLUTION : 768px to 899px (tablets)

@media screen and (max-width:899px) {

    .main_page {
        flex-basis: 50%;
    }

}

//------- RESOLUTION : 350px to 767px (phones

@media screen and (max-width:767px) {

    .project {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding-top: 10px;
        gap: 15px;

        background: linear-gradient(#FFD0D0, #FDF0D8);
        box-shadow: 3px 3px 0 #474350, 3px 3px #FFFFF3 inset;

        height: 130px;
        width: 130px;

        cursor: pointer;

        img {
            width: 50px;
            height: 50px;
        }

    }

    .main_page {
       flex-basis: auto; 
    }

}
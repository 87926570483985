//---------- RESOLUTION : 1400px and more screens (base)

//General settings

.main_overall_container {
    background-image: url('../../assets/background_universe.jpg');
    background-size: cover;
    position: relative;
    min-height: 100vh;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    .redirection_main_computer {
        display: none;
    }
}

.main_pc {
    display: flex;
    flex-direction: column;
    width: 100%;
    place-items: center;
}

.main_phone {
    display: none;
}

.main_container {
    display: flex;
    align-items: flex-start;
    gap: 50px;
    padding-top: 40px;
}


.main_left {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    gap: 30px;
    height: 90vh;
}

//Center div (icons + pic)

.main_center {
    display: flex;
    flex-direction: column;
    gap: 30px;

    //Icons

    .center_icons {
        display: flex;
        justify-content: space-around;

        img {
            height: 60px;
        }

        .trash_icon,
        .about_icon {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 15px;

            cursor: pointer;
            color: #FFFFF3;
            text-decoration: none;
        }
    }

    //Img

    .center {
        border-radius: 10px;
        border: solid 3px #FFFFF3;

        img {
            border-radius: 10px;
            height: 310px;
            width: 260px;
            object-fit: cover;

        }
    }

}

.main_right {
    display: flex;
    flex-direction: column;
    gap: 30px;
}


//--------- RESOLUTION : 1000px to 1399px screens (small laptops)

@media screen and (max-width:1399px) {
    //General settings

    .main_container {
        gap: 40px;
        padding-top: 40px;
    }

    //Center div (icons + pic)

    .main_center {
        gap: 30px;

        //Icons
        .center_icons {

            img {
                height: 40px;
            }

            .trash_icon,
            .about_icon {
                gap: 10px;
            }
        }

        //Img

        .center {

            img {
                height: 230px;
                width: 180px;

            }
        }

    }

}

//-------- RESOLUTION : 900px to 999px screens (tablets)

@media screen and (max-width:999px) {
    //General settings

    .main_container {
        gap: 30px;
    }

    //Center div (icons + pic)

    .main_center {

        //Img
        .center {

            img {
                height: 220px;
                width: 150px;

            }
        }

    }

}

//-------- RESOLUTION : 800px to 899px screens (tablets)

@media screen and (max-width:899px) {
    //General settings

    .main_container {
        gap: 25px;
    }

    //Center div (icons + pic)

    .main_center {

        //Img
        .center {

            img {
                width: 160px;

            }
        }

    }

}

//-------- RESOLUTION : 768px to 799px screens (tablets)

@media screen and (max-width:799px) {
    //General settings

    .main_container {
        gap: 25px;
    }

    //Center div (icons + pic)

    .main_center {

        //Img
        .center {

            img {
                width: 160px;

            }
        }

    }

}

//-------- RESOLUTION : 350px to 767px screens (phone)


@media screen and (max-width:767px) {

    .main_overall_container {
        justify-content: center;

        .main_pc {
            display: none;
        }
        
        .main_phone {
            display: flex;
        }

/*         .redirection_main_computer {
            display: flex;
        }

        .main_container {
            display: none;
        }

        .modal_overlay {
            display: none;
        }
 */
    }

}
//------- RESOLUTION : 1400px and more screens (base)
//Base settings

.music {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 330px;
    height: 180px;

    background-color: #F1EDFF;
    box-shadow: 3px 3px 0 #474350, 3px 3px #FFFFF3 inset;

    //Title

    h3 {
        display: flex;
        align-items: center;

        height: 40px;
        width: 320px;

        background-color: #FFADC7;
        color: #474350;
        box-shadow: 2px 2px 0 #474350, 2px 2px #FFFFF3 inset;

        margin: 5px;
        padding-left: 10px;
    }

    //Main div

    .music_main {

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        height: 130px;

        .music_icons {
            display: flex;
            justify-content: center;
            gap: 10px;
            height: 40px;
            width: 100%;

            button {
                display: flex;
                justify-content: center;
                align-items: center;

                object-fit: contain;
                cursor: pointer;
                height: 35px;
                width: 40px;
                padding: 0;

                img {
                    width: 15px;
                    height: 15px;
                }

            }
        }

        .seek_bar {

            display: flex;
            align-items: center;
            justify-content: space-around;
            width: 100%;
            height: 40px;
            padding: 0 10px;
            gap: 6px;

            img {
                height: 30px;
                cursor: pointer;
            }

            input[type=range] {
                -webkit-appearance: none;
                /* Hides the slider so that custom slider can be made */
                width: 100%;
                /* Specific width is required for Firefox. */
                background: transparent;
                /* Otherwise white in Chrome */
            }

            input[type=range]::-webkit-slider-thumb {
                -webkit-appearance: none;
            }

            input[type=range]:focus {
                outline: none;
                /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
            }

            input[type=range]::-ms-track {
                width: 100%;
                cursor: pointer;

                /* Hides the slider so custom styles can be added */
                background: transparent;
                border-color: transparent;
                color: transparent;
            }

            //------- Styling the thumb

            input[type=range]::-webkit-slider-thumb {
                -webkit-appearance: none;
                height: 30px;
                width: 15px;
                border-radius: 0px;
                background: #d9d9d9;
                border: solid 1px #474350;
                cursor: pointer;
                margin-top: -15px;
                /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
                box-shadow: 2px 2px 0 #474350, 2px 2px #FFFFF3 inset;
                /* Add cool effects to your sliders! */

                &:hover {
                    background: #CDCAC6;
                }
            }

            /* All the same stuff for Firefox */
            input[type=range]::-moz-range-thumb {
                box-shadow: 2px 2px 0 #474350, 2px 2px #FFFFF3 inset;
                border: solid 1px #474350;
                height: 30px;
                width: 15px;
                border-radius: 0px;
                background: #d9d9d9;
                cursor: pointer;

                &:hover {
                    background: #CDCAC6;
                }
            }

            /* All the same stuff for IE */
            input[type=range]::-ms-thumb {
                box-shadow: 2px 2px 0 #474350, 2px 2px #FFFFF3 inset;
                border: solid 1px #474350;
                height: 30px;
                width: 15px;
                border-radius: 0px;
                background: #d9d9d9;
                cursor: pointer;

                &:hover {
                    background: #CDCAC6;
                }
            }

            //Bar

            //Chrome

            input[type=range]::-webkit-slider-runnable-track {
                width: 100%;
                height: 3px;
                box-shadow: 2px 2px 0 #FFFFF3;
                background: #474350;
                border-radius: 0px;
                border: none;
            }

            input[type=range]:focus::-webkit-slider-runnable-track {
                background: #474350;
            }

            //Firefox

            input[type=range]::-moz-range-track {
                width: 100%;
                height: 3px;
                box-shadow: 2px 2px 0 #FFFFF3;
                background: #474350;
                border-radius: 0px;
                border: none;
            }

            //IE

            input[type=range]::-ms-track {
                width: 100%;
                height: 3px;
                cursor: pointer;
                background: transparent;
                border-color: transparent;
                border-width: 16px 0;
                color: transparent;
            }

            input[type=range]::-ms-fill-lower {
                background: #474350;
                border: none;
                border-radius: 0px;
                box-shadow: 2px 2px 0 #FFFFF3;
            }

            input[type=range]:focus::-ms-fill-lower {
                background: #3071a9;
            }

            input[type=range]::-ms-fill-upper {
                background: #474350;
                border: none;
                border-radius: 0px;
                box-shadow: 2px 2px 0 #FFFFF3;
            }

            input[type=range]:focus::-ms-fill-upper {
                background: #474350;
            }

        }
    }

}

//--------- RESOLUTION : 1000px to 1399px (small laptops)

@media screen and (max-width:1399px) {

    //Base settings

    .music {
        width: 280px;
        height: 150px;

        //Title

        h3 {
            width: 270px;
        }

        //Main div

        .music_main {
            height: 90px;
            gap: 0px;
            justify-content: center;
            margin-top: 7px;

            .music_icons {

                button {
                    height: 25px;
                    width: 35px;

                    img {
                        width: 15px;
                        height: 15px;
                    }

                }
            }

            .volume_control {

                input[type=range]::-webkit-slider-thumb {
                    margin-top: -10px;
                    height: 20px;
                    width: 10px;
                }

                /* All the same stuff for Firefox */
                input[type=range]::-moz-range-thumb {
                    height: 20px;
                    width: 10px;
                }

                /* All the same stuff for IE */
                input[type=range]::-ms-thumb {
                    height: 20px;
                    width: 10px;
                }

            }


        }
    }

}


//-------- RESOLUTION : 900px to 999px (tablets)

@media screen and (max-width:999px) {

    //Base settings

    .music {
        width: 250px;
        height: 170px;

        //Title

        h3 {
            width: 240px;
            height: 30px;
        }

        .music_main {
            height: 123px;

            .music_icons {

                button {
                    height: 30px;
                }
            }

            .volume_control {
                width: 90%;
            }
        }
    }

}


//-------- RESOLUTION : 768px to 899px (tablets)

@media screen and (max-width:899px) {

    //Base settings

    .music {
        width: 200px;

        //Title

        h3 {
            width: 190px;
        }

    }

}


//-------- RESOLUTION : 300px to 767px (phone)

@media screen and (max-width:767px) {

    .music {
        width: 100%;
        height: 15vh;
        justify-content: center;

        background-color: #F1EDFF;
        box-shadow: 3px 3px 0 #474350, 3px 3px #FFFFF3 inset;

        //Title

        h3 {
            display: none;
        }

        //Main div

        .music_main {
            height: 90%;
            margin-top: 10px;
            gap: 0;
            justify-content: space-around;

            .music_icons {

                button {
                    height: 30px;
                }

            }
        }
    }


}
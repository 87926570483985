//-------- RESOLUTION : 768px to 1400px and more (everything except phones)

//Basic settings

.collapsible,
.toggle {
    flex: 1
}

.collapsible {

    //Title

    .collapsible_title {
        height: 40px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        margin: 0 5px;
        padding: 0 20px;


        background-color: #CDCAC6;
        color: #474350;
        box-shadow: 2px 2px 0 #474350, 2px 2px #FFFFF3 inset;

        cursor: pointer;

    }

    .toggle {
        display: flex;
        justify-content: stretch;
        align-items: center;

        background-color: #FFFFF3;
        color: #474350;
        box-shadow: 2px 2px 0 #474350, 2px 2px #FFFFF3 inset;

        margin: 0 5px;

        img {
            height: 50px;
            width: 50px;
            object-fit: cover;
            border-radius: 10px;
            margin: 20px;
        }

        .collapsible_text {
            padding: 20px;
            flex-basis: 80%;
            white-space: pre-wrap;
        }

    }

}


//--------- RESOLUTION : 350px to 767px (phones)

@media screen and (max-width:767px) {
    .collapsible {
        .toggle {
            img {
                display: none;
            }

            .collapsible_text {
                flex-basis: 100%;
                line-height: 20px;
                padding: 15px;
            }
        }
    }
}
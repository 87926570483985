.loading_container {

    background-image: url('../assets/background_universe.jpg');
    background-size: cover;
    height: 100vh;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .loading_element {
        width: 450px;
        background-color: #CDCAC6;
        box-shadow: 3px 3px 0 #474350, 3px 3px #FFFFF3 inset;

        display: flex;
        flex-direction: column;


        h3 {
            background-color: #30345A;
            padding: 10px;
            color: #FFFFF3;
            margin: 5px;
            padding-left: 10px;
        }

        .loading_content {

            display: flex;
            align-items: center;
            padding: 20px;
            gap: 30px;

            img {
                width: 100px;
                object-fit: contain;

            }
            
            p {
                padding: 10px;
            }

        }
    }

}

@media screen and (max-width:767px) {

    .loading_container {
    
        .loading_element {
            width: 300px;
    
    
            .loading_content {
                padding: 20px;
                gap: 10px;
    
                img {
                    width: 70px;   
                }
    
            }
        }
    
    }


}
.phone_projects {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    background-image: url('../../assets/background_universe.jpg');
    background-repeat: no-repeat;
    background-size: cover;


    .phone_projects_title,
    .phone_stats_container,
    .projects,
    .modal_overlay {
        display: none;
    }

    .redirection {
        display: flex;
    }

}

@media screen and (max-width:767px) {
    .phone_projects {
        align-items: stretch;
        justify-content: flex-start;

        .redirection {
            display: none;
        }

        .phone_projects_title {
            display: flex;
            align-items: center;
            justify-content: center;

            color: #FFFFF3;
            height: 6vh;

            background-color: #30345A;
            margin: 10px 5px;
        }

        .phone_stats_container {
            display: flex;
        }

        .phone_footer {
            display: flex;
        }

        .projects {
            display: flex;
            height: auto;

            .project {
                justify-content: center;
            }
        }

        .modal_overlay {
            display: flex;
        }
    }

    .cv_phone_projects {

        .projects {
            display: flex;

            .project {
                justify-content: flex-start;
                padding-top: 25px;
                gap: 5px;
            }
        }

    }

}
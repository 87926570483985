//-------- RESOLUTION : 1400px and more screens (base)

//General footer basic settings

footer {
    background-color: #CDCAC6;
    height: 40px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 2;

    //Start button

    button {
        margin-left: 30px;
    }

    .footer_menu {
        position: absolute;
        bottom: 5vh;
        height: 200px;

        background-color: #CDCAC6;
        box-shadow: 2px 2px 0 #474350, 2px 2px #FFFFF3 inset;

        .menu_logo {
            height: 198px;
            align-self: flex-end;
            margin-left: 2px;
        }

        .menu_buttons {

            display: flex;
            flex-direction: column;

            .menu_button {
                text-decoration: none;
                color: inherit;
                padding: 10px 30px;

                display: flex;
                align-items: center;
                gap: 20px;
                flex-grow: 0.25;

                img {
                    height: 20px;
                    width: 20px;
                }

                &:hover {
                    background-color: #30345A;
                    color: #FFFFF3;

                }

            }
        }
    }

    //Right nav (fake buttons)

    nav {


        ul {
            list-style-type: none;

            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 10px;
            height: 5vh;
            padding-right: 30px;

            li {
                padding: 5px 10px;
                height: 25px;
                box-shadow: 2px 2px 0 #474350, 2px 2px #FFFFF3 inset;
                display: flex;
                align-items: center;

                background-color: #D9D9D9;
            }

            .footer_nav_button {
                cursor: pointer;
            }

            //Hour button

            .hour {
                box-shadow: 2px 2px 0 #FFFFF3, 2px 2px #474350 inset !important;
            }

            //Icons

            img {
                height: 17px;
                width: 17px;
            }
        }
    }

    //Volume modal 

    .volume_modal {
        position: absolute;
        display: flex;
        align-items: center;
        right: 150px;
        bottom: 5vh;
        height: 60px;

        background-color: #CDCAC6;
        box-shadow: 2px 2px 0 #474350, 2px 2px #FFFFF3 inset;

        .volume_control {

            display: flex;
            align-items: center;
            justify-content: space-around;
            width: 100%;
            height: 40px;
            gap: 10px;

            img {
                height: 30px;
                cursor: pointer;
            }

            input[type=range] {
                -webkit-appearance: none;
                /* Hides the slider so that custom slider can be made */
                width: 100%;
                /* Specific width is required for Firefox. */
                background: transparent;
                /* Otherwise white in Chrome */
            }

            input[type=range]::-webkit-slider-thumb {
                -webkit-appearance: none;
            }

            input[type=range]:focus {
                outline: none;
                /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
            }

            input[type=range]::-ms-track {
                width: 100%;
                cursor: pointer;

                /* Hides the slider so custom styles can be added */
                background: transparent;
                border-color: transparent;
                color: transparent;
            }

            //------- Styling the thumb

            input[type=range]::-webkit-slider-thumb {
                -webkit-appearance: none;
                height: 30px;
                width: 15px;
                border-radius: 0px;
                background: #d9d9d9;
                border: solid 1px #474350;
                cursor: pointer;
                margin-top: -15px;
                /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
                box-shadow: 2px 2px 0 #474350, 2px 2px #FFFFF3 inset;
                /* Add cool effects to your sliders! */

                &:hover {
                    background: #CDCAC6;
                }
            }

            /* All the same stuff for Firefox */
            input[type=range]::-moz-range-thumb {
                box-shadow: 2px 2px 0 #474350, 2px 2px #FFFFF3 inset;
                border: solid 1px #474350;
                height: 30px;
                width: 15px;
                border-radius: 0px;
                background: #d9d9d9;
                cursor: pointer;

                &:hover {
                    background: #CDCAC6;
                }
            }

            /* All the same stuff for IE */
            input[type=range]::-ms-thumb {
                box-shadow: 2px 2px 0 #474350, 2px 2px #FFFFF3 inset;
                border: solid 1px #474350;
                height: 30px;
                width: 15px;
                border-radius: 0px;
                background: #d9d9d9;
                cursor: pointer;

                &:hover {
                    background: #CDCAC6;
                }
            }

            //Bar

            //Chrome

            input[type=range]::-webkit-slider-runnable-track {
                width: 100%;
                height: 3px;
                box-shadow: 2px 2px 0 #FFFFF3;
                background: #474350;
                border-radius: 0px;
                border: none;
            }

            input[type=range]:focus::-webkit-slider-runnable-track {
                background: #474350;
            }

            //Firefox

            input[type=range]::-moz-range-track {
                width: 100%;
                height: 3px;
                box-shadow: 2px 2px 0 #FFFFF3;
                background: #474350;
                border-radius: 0px;
                border: none;
            }

            //IE

            input[type=range]::-ms-track {
                width: 100%;
                height: 3px;
                cursor: pointer;
                background: transparent;
                border-color: transparent;
                border-width: 16px 0;
                color: transparent;
            }

            input[type=range]::-ms-fill-lower {
                background: #474350;
                border: none;
                border-radius: 0px;
                box-shadow: 2px 2px 0 #FFFFF3;
            }

            input[type=range]:focus::-ms-fill-lower {
                background: #3071a9;
            }

            input[type=range]::-ms-fill-upper {
                background: #474350;
                border: none;
                border-radius: 0px;
                box-shadow: 2px 2px 0 #FFFFF3;
            }

            input[type=range]:focus::-ms-fill-upper {
                background: #474350;
            }

        }
    }

}



//-------- RESOLUTION : 1000px to 1399px (small laptops) && 999px to 768px (tablets)

@media screen and (max-width:1399px) {

    //General footer basic settings

    footer {
        //Start button

        button {
            margin-left: 30px;
        }

        .footer_menu {
            bottom: 5vh;
            height: 150px;

            .menu_logo {
                height: 148px;
            }

            .menu_buttons {

                .menu_button {
                    padding: 5px 30px;
                    gap: 10px;

                    img {
                        height: 17px;
                        width: 17px;
                    }

                }
            }
        }

        //Right nav (fake buttons)

        nav {


            ul {

                li {
                    padding: 5px 10px;
                    height: 25px;
                }
            }
        }
    }
}

//-------- RESOLUTION : 350px to 767 px (phone)

@media screen and (max-width:767px) {

    footer {
        display: none;
    }
}
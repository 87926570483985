//------- RESOLUTION : 1400px and more screens (base)

//Basic settings

.projects {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 550px;
    height: 310px;

    background-color: #CDCAC6;
    box-shadow: 3px 3px 0 #474350, 3px 3px #FFFFF3 inset;

    //Top frame with title and tool bar

    .projects_title {
        display: flex;
        flex-direction: column;

        h3 {
            display: flex;
            align-items: center;

            height: 40px;
            width: 540px;

            background-color: #30345A;
            color: #FFFFF3;

            margin: 5px;
            padding-left: 10px;
        }


        nav {
            height: 30px;
            display: flex;
            align-items: center;
            padding-left: 15px;

            ul {
                display: flex;
                gap: 7px;
            }
        }
    }

    //Main frame with projects icons

    .projects_main {
        width: 540px;
        height: 180px;

        background: linear-gradient(#FFD0D0, #FDF0D8);
        box-shadow: 2px 2px 0 #FFFFF3, 2px 2px #474350 inset;

        display: flex;
        align-items: center;
        justify-content: flex-start;
        row-gap: 30px;
        flex-wrap: wrap;
        overflow: scroll;

        padding: 25px 30px;

        .phone_bottom_projects {
            display: none;
        }
    }

    //Bottom of the frame with number of projects

    .projects_footer {
        display: flex;
        align-items: center;

        width: 540px;
        height: 40px;
        margin-top: 5px;
        padding-left: 10px;

        box-shadow: 2px 2px 0 #FFFFF3, 2px 2px #474350 inset;

    }
}



//--------- RESOLUTION : 1200px to 1399px (small laptops)

@media screen and (max-width:1399px) {

    //Basic settings


    .projects {
        height: 290px;

        .projects_main {
            height: 160px;
        }
    }

}

//--------- RESOLUTION : 1000px to 1199px (small laptops)

@media screen and (max-width:1199px) {

    //Basic settings


    .projects {
        width: 400px;

        //Top frame with title and tool bar

        .projects_title {

            h3 {
                width: 390px;
            }
        }

        //Main frame with projects icons

        .projects_main {
            width: 390px;
            row-gap: 20px;

            padding: 20px;
        }

        //Bottom of the frame with number of projects

        .projects_footer {
            width: 390px;
        }
    }

}



//-------- RESOLUTION : 900px to 999px (tablets)

@media screen and (max-width:999px) {

    //Basic settings


    .projects {
        width: 420px;
        height: 260px;

        //Top frame with title and tool bar

        .projects_title {

            h3 {
                width: 410px;
                height: 30px;
            }
        }

        //Main frame with projects icons

        .projects_main {
            width: 410px;
            height: 150px;
        }

        //Bottom of the frame with number of projects

        .projects_footer {
            width: 410px;
            height: 30px;
        }
    }

}


//-------- RESOLUTION : 800px to 899px (tablets)

@media screen and (max-width:899px) {

    //Basic settings


    .projects {
        width: 370px;

        //Top frame with title and tool bar

        .projects_title {

            h3 {
                width: 360px;
            }
        }

        //Main frame with projects icons

        .projects_main {
            width: 360px;
        }

        //Bottom of the frame with number of projects

        .projects_footer {
            width: 360px;
        }
    }

}


//-------- RESOLUTION : 768px to 799px (tablets)

@media screen and (max-width:799px) {

    //Basic settings


    .projects {
        width: 330px;

        //Top frame with title and tool bar

        .projects_title {

            h3 {
                width: 320px;
            }
        }

        //Main frame with projects icons

        .projects_main {
            width: 320px;
        }

        //Bottom of the frame with number of projects

        .projects_footer {
            width: 320px;
        }
    }

}



//--------- RESOLUTION : 767px to 350px (phones)

@media screen and (max-width:767px) {

    //Basic settings

    .projects {
        justify-content: space-between;
        align-items: stretch;

        width: 100%;

        background-color: transparent;
        box-shadow: none;

        //Top frame with title and tool bar

        .projects_title {
            display: none;
        }

        //Main frame with projects icons

        .projects_main {
            width: 100%;
            height: auto;

            background: transparent;
            box-shadow: none;

            display: flex;
            align-items: flex-start;
            justify-content: center;

            gap: 30px;
            flex-wrap: wrap;

            padding: 20px 30px 50px 30px;

            .phone_bottom_projects {
                display: flex;
                height: 20vh;
                width: 100%;
                border-radius: 10px;
                object-fit: cover;
            }
        }

        //Bottom of the frame with number of projects

        .projects_footer {
            display: none;

        }
    }

}

@media screen and (max-width:509px) {

    .projects {

        .projects_main {

            .phone_bottom_projects {
                display: none;
            }
        }
    }
}

//------- RESOLUTION : 1400px and more screens (base)

//Base settings for the notes section

.notes {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 330px;
    height: 440px;

    background-color: #CDCAC6;
    box-shadow: 3px 3px 0 #474350, 3px 3px #FFFFF3 inset;

    //General section title

    .notes_title {
        display: flex;
        flex-direction: column;

        h3 {
            display: flex;
            align-items: center;

            height: 40px;
            width: 320px;

            background-color: #FFADC7;
            color: #474350;
            box-shadow: 2px 2px 0 #474350, 2px 2px #FFFFF3 inset;

            margin: 5px;
            padding-left: 10px;
        }

        nav {
            height: 30px;
            display: flex;
            align-items: center;
            padding-left: 15px;

            ul {
                display: flex;
                gap: 7px;
            }
        }
    }

    //Main div

    .notes_main {
        width: 320px;
        height: 310px;

        background: linear-gradient(#F1EDFF, #FFD0D0);
        box-shadow: 2px 2px 0 #FFFFF3, 2px 2px #474350 inset;

        display: flex;
        align-items: center;
        flex-wrap: wrap;
        overflow: scroll;

        padding: 25px 50px;

        .note_2 {
            position: relative;
            bottom: 50px;
            left: 50px;
        }

        .note_3 {
            margin-top: -100px;
            bottom: 90px;
            z-index: 1;
        }
    }

    //Notes footer

    .notes_footer {
        display: flex;
        align-items: center;

        width: 320px;
        height: 40px;
        margin-top: 5px;
        padding-left: 10px;

        box-shadow: 2px 2px 0 #FFFFF3, 2px 2px #474350 inset;

    }

}

//------- RESOLUTION : 1000px to 1399px (small laptops)

@media screen and (max-width:1399px) {

    //Base settings for the notes section

    .notes {
        width: 280px;
        height: 370px;

        //General section title

        .notes_title {

            h3 {
                height: 40px;
                width: 270px;
            }
        }


        //Main div

        .notes_main {
            width: 270px;
            height: 240px;
            padding: 10px 20px;
        }

        //Notes footer

        .notes_footer {
            width: 270px;
        }

    }

}


//------- RESOLUTION : 900px to 999px (tablets)

@media screen and (max-width:999px) {

    //Base settings for the notes section

    .notes {
        width: 250px;
        height: 350px;

        //General section title

        .notes_title {

            h3 {
                height: 30px;
                width: 240px;
            }
        }


        //Main div

        .notes_main {
            width: 240px;
            height: 230px;
            justify-content: center;
        }

        //Notes footer

        .notes_footer {
            width: 240px;
        }

    }

}

//------- RESOLUTION : 768px to 899px (tablets)

@media screen and (max-width:899px) {

    //Base settings for the notes section

    .notes {
        width: 200px;

        //General section title

        .notes_title {

            h3 {
                width: 190px;
            }
        }
        
        .notes_main {
            justify-content: flex-start;
        }


        //Main div

        .notes_main {
            width: 190px;
            height: 230px;
        }

        //Notes footer

        .notes_footer {
            width: 190px;
        }

    }

}
.cv_details {

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    background-color: #CDCAC6;
    box-shadow: 3px 3px 0 #474350, 3px 3px #FFFFF3 inset;
    padding: 7px;

    .cv_buttons_title {
        background-color: #30345A;
/*         box-shadow: 2px 2px 0 #474350, 2px 2px #FFFFF3 inset; */
        padding: 15px 0px;
        width: 100%;
        color: #FFFFF3;
        text-align: center;
        margin: 0 px;
    }

    //Nav and buttons
    .cv_buttons {
        display: flex;
        gap: 2px;
        padding: 0;


        .cv_button {
            background-color: #CDCAC6;
            box-shadow: 2px 0px 0 #474350, 0px 2px 0 #CDCAC6, 2px 2px #FFFFF3 inset;
            padding: 10px 0px;
            width: 120px;
            text-align: center;
            cursor: pointer;

            &:hover,
            &.selected {
                z-index: 1;
            }
        }

    }
}

@media screen and (max-width:767px) {

    .cv_details {
        width: 100%;
        padding: 40px 20px 70px 20px;

        background-color: transparent;
        box-shadow: none;
        gap: 0;

        .cv_buttons_title {
            display: none;
        }


        .cv_buttons {
            width: 95%;
            gap: 25px;

            .cv_button {
                display: flex;
                flex-direction: column;
                align-items: center;
                background-color: #C2DCFF;
                gap: 10px;
                text-decoration: none;
                color: inherit;

                img {
                    height: 35px;
                    width: 35px;
                }

            }
        }
    }
}
//---------- RESOLUTION : 1400px and more (base)

//Basic settings

.landing_container_overall {
    background: linear-gradient(#FFD0D0, #FDF0D8);
    width: 100%;
    min-height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;
}

//Main frame settings

.landing_main_frame {
    width: 1000px;
    height: 720px;

    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;

    //Computer frame
    .computer_frame {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 2;
    }

    .phone_frame {
        display: none;
    }

    .phone_logo {
        display: none;
    }

    //Stars 

    .stars {
        display: flex;
        gap: 500px;

        position: absolute;
        bottom: 390px;
        z-index: 2;

        img {
            height: 60px;
        }
    }

    //Profile (img + title + buttons)

    .profile {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;

        .stars_background {
            width: 850px;
            height: 280px;
            object-fit: cover;
            z-index: 1;
        }

        .wallpaper {
            width: 850px;
            height: 400px;
            object-fit: cover;
            position: absolute;
            top: 200px;
        }

        .profile_infos {
            position: absolute;
            top: 220px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 30px;

            h2 {
                font-size: 2em;
            }

            img {
                height: 120px;
                width: 120px;

                border-radius: 10px;
                border: solid 3px #FFFFF3;
                z-index: 2;
            }
        }

        //Landing buttons 

        .landing_buttons {
            display: flex;
            flex-direction: column;
            padding-top: 150px;
            gap: 15px;
            z-index: 2;

            .link_button {
                padding: 15px 70px;
                border-radius: 0;
                border: solid 2px #474350;
                box-shadow: 3px 3px 0 #474350, 3px 3px #FFFFF3 inset;

                color: inherit;
                font-family: inherit;
                font-size: 1.2em;
                background-color: #C2DCFF;

                cursor: pointer;

                text-decoration: none;
                text-align: center;

                &:hover {
                    background-color: #FFADC7;
                }
            }

        }

        .landing_buttons_phone {
            display: none;
        }

    }


    //Frame footer

    .landing_footer {
        position: absolute;
        z-index: 3;

        margin-top: 650px;

        display: flex;
        justify-content: space-around;
        width: 100%;

        img {
            height: 50px;
        }

        .power_icons,
        .utils_icons {
            display: flex;
            align-items: baseline;
            gap: 10px;
        }

        .phone_icons_left, .phone_icons_right, .central_button {
            display: none;
        }

    }

}

//---------- RESOLUTION : 1000px to 1399px (small laptops) && 999px to 768px (tablets)

@media screen and (max-width:1399px) {

    //Main frame settings

    .landing_main_frame {
        width: 800px;
        height: 600px;

        //Stars 

        .stars {
            gap: 330px;
            bottom: 330px;

            img {
                height: 50px;
            }
        }

        //Profile (img + title + buttons)

        .profile {

            .stars_background {
                width: 750px;
                height: 230px;
            }

            .wallpaper {
                width: 750px;
                height: 300px;
            }

            .profile_infos {
                top: 180px;
                gap: 20px;

                h2 {
                    font-size: 1.7em;
                }

                img {
                    height: 100px;
                    width: 100px;
                }
            }

            //Landing buttons 

            .landing_buttons {
                padding-top: 125px;

                .link_button {
                    padding: 10px 60px;
                    font-size: 1.1em;
                }

            }

        }

        //Frame footer

        .landing_footer {
            margin-top: 545px;

            img {
                height: 40px;
            }

            .power_icons,
            .utils_icons {
                display: flex;
                align-items: baseline;
                gap: 10px;
            }

        }

    }

}

// ------------- RESOLUTION : 767px to 350px (phones)

@media screen and (max-width:767px) {

    //Main frame settings

    .landing_main_frame {
        width: 360px;
        height: 640px;
        justify-content: center;

        //Computer frame
        .computer_frame {
            display: none;
        }

        .phone_frame {
            display: flex;
            position: absolute;
            z-index: 2;
            width: 360px;
        }
        
        .phone_logo {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;

            position: absolute;
            z-index: 3;
            top: 43px;

            img {
                height: 47px;
            }

            font-family: "Lilita";
            color: #FFFFF3;
        }
        //Stars 

        .stars {
            display: none;
        }

        //Profile (img + title + buttons)

        .profile {
            justify-content: space-between;
            align-items: center;
            width: 100%;

            .stars_background {
                position: absolute;
                top: 0;
                width: 100%;
                height: 250px;
            }

            .wallpaper {
                display: none;
            }

            .profile_infos {
                top: 200px;
            }

            //Landing buttons 

            .landing_buttons {
                display: none;
            }

            .landing_buttons_phone {
                display: flex;
                flex-direction: column;
                gap: 15px;
                z-index: 2;
                top: 380px;
                position: absolute;
    
                .link_button {
                    padding: 10px 60px;
                    border-radius: 0;
                    border: solid 2px #474350;
                    box-shadow: 3px 3px 0 #474350, 3px 3px #FFFFF3 inset;
    
                    color: inherit;
                    font-family: inherit;
                    font-size: 1em;
                    background-color: #C2DCFF;

                    text-decoration: none;
                    text-align: center;
    
                }
                

            }

        }


        //Frame footer

        .landing_footer {
            position: absolute;
            z-index: 3;
    
            bottom: 0px;
    
            display: flex;
            justify-content: space-around;
            width: 100%;
    
            img {
                height: 40px;
            }

            .power_icons, .cd_bar, .utils_icons {
                display: none;
            }

            .phone_icons_left, .phone_icons_right, .central_button {
                display: flex;
                justify-content: center;
            }

            .phone_icons_left, .phone_icons_right {
                gap: 15px;
            }

            .central_button {
                position: absolute;
                bottom: 22px;
            }

        }

    }

}
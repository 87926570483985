//Fonts 

@font-face {
    font-family: "Windows";
    src: url('../styles/fonts/Windows_font/Windows_Regular.ttf');
}

@font-face {
    font-family: "Lilita";
    src: url('../styles/fonts/lilita-one/LilitaOne-Regular.ttf');
}

//Main basic divs and sections

html {
    margin: 0px;
    height: 100vh;
    width: 100%;
}

body,
#root {
    margin: 0px;
    min-height: 100vh;
    width: 100%;
    line-height: 1;
    font-family: 'Windows';
    color: #474350;
}


// ------------- RESOLUTION : 1400px and more screens (base)
.container {
    width: 1400px;
    min-height: 95vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

//Basic elements

* {
    box-sizing: border-box;
}

h2 {
    font-size: 1.8em;
}

button {
    padding: 5px 25px;
    border-radius: 0;
    border: solid 1px #474350;
    box-shadow: 2px 2px 0 #474350, 2px 2px #FFFFF3 inset;

    color: inherit;
    font-family: inherit;
    font-weight: bold;

    cursor: pointer;

    text-decoration: none;
    text-align: center;

    text-transform: uppercase;
}

//------------- RESOLUTION : 1000px to 1399px screens (small laptops)

@media screen and (max-width:1399px) {
    .container {
        width: 90%;
        justify-content: space-around;
    }

    h2 {
        font-size: 1.5em;
    }

    button {
        padding: 3px 20px;
    }
}


//------------- RESOLUTION : 900px to 999px screens (tablets)

@media screen and (max-width:999px) {
    .container {
        width: 900px;
        justify-content: center;
    }

    h2 {
        font-size: 1em;
    }

    button {
        padding: 3px 20px;
    }
}

//------------- RESOLUTION : 800px to 899px screens (tablets)

@media screen and (max-width:899px) {
    .container {
        width: 800px;
    }
}

//------------- RESOLUTION : 768px to 799px screens (tablets)

@media screen and (max-width:799px) {
    .container {
        width: 760px;
    }
}

// ------------- RESOLUTION : 767px to 300px (phones)

@media screen and (max-width:767px) {
    .container {
        width: 100%;
        min-height: 95vh;
        display: flex;
        flex-direction: column;
    }

    //Basic elements

    h2 {
        font-size: 1.8em;
    }
}
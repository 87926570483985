.overall_phone_header {
    display: none;
}

@media screen and (max-width:767px) {

    .overall_phone_header {

        display: flex;
        flex-direction: column;
        height: 15vh;

        .phone_header {
            height: 5vh;
            display: flex;
            padding: 0 20px;

            img {
                height: 20px;
                width: 20px;
                object-fit: contain;
            }

            .parameters_icon {
                height: 30px;
                width: 30px;
                cursor: pointer;
            }

            .phone_main_icons_left {
                display: flex;
                gap: 10px;
                justify-content: flex-start;
                align-items: center;

                .phone_main_messages {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                }
            }

            .phone_main_icons_right {
                display: flex;
                justify-content: flex-end;
                gap: 10px;
            }
        }

        .date_and_hour {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 10vh;
            background-color: #CDCAC6;
            border-top: 3px solid #474350;
            gap: 5px;

            .phone_main_time {
                font-size: 1.7em;
            }
        }

    }
}